import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  audienceIds: ImmutablePropTypes.listOf(PropTypes.string),
  imageId: PropTypes.string
});

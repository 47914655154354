import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

const imageSizeShape = ImmutablePropTypes.mapContains({
  key: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  url: PropTypes.string,
  id: PropTypes.string
});

export default ImmutablePropTypes.mapContains({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  alt: PropTypes.string,
  tage: PropTypes.string,
  caption: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  sizes: ImmutablePropTypes.listOf(imageSizeShape)
});

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  id: PropTypes.string,
  name: PropTypes.string,
  categoryName: PropTypes.string,
  locationId: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  capacity: PropTypes.number
});

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

function parseDateTime(dateTimeStr) {
  const [dateStr, timeStr] = dateTimeStr.split('T');
  return {
    date: dateStr ? moment(dateTimeStr).format('LL') : null,
    time: timeStr ? moment(dateTimeStr).format('LT') : null
  };
}

export default function EventDateTime({ start, end, includeYear }) {
  const intl = useIntl();
  const { date: startDate, time: startTime } = parseDateTime(start);
  const { date: endDate, time: endTime } = parseDateTime(end);
  const isMultiDay = startDate !== endDate;
  const isAllDay = !startTime && !endTime;
  const eventStartTime = startTime?.replace(/\s+/g, '').toLowerCase();
  const eventEndTime = endTime?.replace(/\s+/g, '').toLowerCase();

  function formatDate(date) {
    if (includeYear) {
      return intl.formatDate(moment.utc(date).toDate(), {
        year: 'numeric',
        weekday: 'long',
        month: 'long',
        day: '2-digit'
      });
    }
    return intl.formatDate(moment.utc(date).toDate(), {
      weekday: 'long',
      month: 'long',
      day: '2-digit'
    });
  }

  function renderEventDate() {
    if (!isMultiDay && !startDate) {
      return null;
    }

    const eventDate = isMultiDay ? (
      <>
        <span aria-hidden>
          {formatDate(start)}&nbsp;
          <FormattedMessage id="to" />
          &nbsp;{formatDate(end)}
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_event_multi_dates" values={{ startDate, endDate }} />
        </ScreenReaderMessage>
      </>
    ) : (
      <>
        <span aria-hidden>{formatDate(start)}</span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_event_date" values={{ startDate }} />
        </ScreenReaderMessage>
      </>
    );
    const eventTime = !isAllDay && (
      <>
        <span aria-hidden>
          {eventStartTime}&#8211;{eventEndTime}
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_event_times" values={{ startTime: eventStartTime, endTime: eventEndTime }} />
        </ScreenReaderMessage>
      </>
    );

    if (isAllDay) {
      return (
        <div className="cp-event-date-time">
          <FormattedMessage id="all_day" />, {eventDate}
        </div>
      );
    }

    return (
      <div className="cp-event-date-time">
        {eventDate}
        <span className="date-join">, </span>
        {eventTime}
      </div>
    );
  }

  return renderEventDate();
}

EventDateTime.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  includeYear: PropTypes.bool
};
